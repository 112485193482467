import React, { useEffect, useState } from "react";
import { Image, Carousel, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import OverlayNav from "./OverlayNav";

import "./Header.css";
import "./Overlay.css";
import "animate.css";

import Logo from "../../Images/elitehomefurniture_crop.png";
import img1 from "../../Images/diodin.jpg";
import img4 from "../../Images/VetroUgaona1.jpg";
import img3 from "../../namestaj/proba2.jpg";
import img2 from "../../namestaj/proba3.jpg";
import { useDispatch } from "react-redux";
import { setPageNumber } from "../tapaciraniNamestaj/tNamestajSlice";

export default function Header() {
  const [index, setIndex] = useState(0);
  const [isNavOpen, setNavOpen] = useState(false);
  const [namestajClose, setNamestajClose] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [namestajShow, setNamestajShow] = useState(false);
  const [tapaciraniShow, setTapaciraniShow] = useState(false);
  const [trpezarijeShow, setTrpezarijeShow] = useState(false);
  const [spavaceShow, setSpavaceShow] = useState(false);

  const dispatch = useDispatch();

  const pageNumerOne = () => {
    dispatch(setPageNumber({ pageNumber: 1 }));
    handleClick();
  };

  const toggleNav = () => {
    setNavOpen((prevState) => !prevState);
    setNamestajClose(true);
    setNamestajShow((prevState) => !prevState);
  };

  const toggleTapaciraniNav = () => {
    setTapaciraniShow((prevState) => !prevState);
    setNamestajClose(true);
  };

  const toggleTrpezarijeNav = () => {
    setTrpezarijeShow((prevState) => !prevState);
    setNamestajClose(true);
  };

  const toggleSpavaceNav = () => {
    setSpavaceShow((prevState) => !prevState);
    setNamestajClose(true);
  };

  const handleClick = () => {
    setClicked((prevState) => !prevState);
    setNamestajShow(false);
    setNavOpen(false);
    setTapaciraniShow(false);
    setTrpezarijeShow(false);
    setSpavaceShow(false);
  };

  const probaNav = () => {
    if (namestajClose === true) {
      setNavOpen(false);
      setNamestajClose(true);
    }
  };

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const handleCloseNav = () => {
    setNavOpen(false);
  };

  useEffect(() => {
    if (isNavOpen === true) {
      document.body.classList.add("navigation-open");
    } else if (clicked === true) {
      document.body.classList.add("navigation-open");
    } else if (clicked === false) {
      document.body.classList.remove("navigation-open");
    } else if (isNavOpen === false) {
      document.body.classList.remove("navigation-open");
    }

    return () => {
      document.body.classList.remove("navigation-open");
    };
  }, [isNavOpen, clicked]);

  return (
    <>
      <nav className='fixed-top bg'>
        <Link to='/'>
          <Image src={Logo} fluid id='logo' />
        </Link>
        <div>
          <ul
            id='navbarnew'
            className={clicked ? "#navbarnew active" : "#navbarnew"}
          >
            <div id='mobile' className='mobileClass' onClick={handleClick}>
              <i id='bar' className={clicked ? "fas fa-times" : ""}></i>
            </div>
            <li>
              <Link className='linkWraper' to='/'>
                <p className='active'>Početna</p>
              </Link>
            </li>
            <li onClick={toggleNav}>
              <p>Nameštaj</p>
            </li>
            {window.innerWidth < 992 && (
              <div
                id='namestajNav'
                className={
                  namestajShow ? "#namestajNav active" : "#namestajNav"
                }
              >
                <li onClick={toggleNav}>
                  <p style={{ color: "darkorange" }}>
                    <i
                      className='fa fa-chevron-left'
                      style={{ color: "white", fontSize: "15px" }}
                      aria-hidden='true'
                    ></i>{" "}
                    Nazad
                  </p>
                </li>
                <li onClick={toggleTapaciraniNav}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      textTransform: "uppercase",
                    }}
                  >
                    Tapacirani nameštaj
                  </p>
                </li>
                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/tvVitrine?kategorije=TV+vitrine'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Tv vitrine
                    </p>
                  </Link>
                </li>
                <li onClick={toggleTrpezarijeNav}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      textTransform: "uppercase",
                    }}
                  >
                    Trpezarije
                  </p>
                </li>

                <li onClick={toggleSpavaceNav}>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      textTransform: "uppercase",
                    }}
                  >
                    Spavaće sobe
                  </p>
                </li>

                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/klubSto?kategorije=klub+stolovi&potkategorije=klub+sto'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Klub stolovi
                    </p>
                  </Link>
                </li>
              </div>
            )}

            {/* Drugi nivo: Tapacirani nameštaj */}

            {window.innerWidth < 992 && (
              <div
                id='tapaciraniNav'
                className={
                  tapaciraniShow ? "#tapaciraniNav active" : "#tapaciraniNav"
                }
              >
                <li onClick={toggleTapaciraniNav}>
                  <p style={{ color: "darkorange" }}>
                    <i
                      className='fa fa-chevron-left'
                      style={{ color: "white", fontSize: "15px" }}
                      aria-hidden='true'
                    ></i>{" "}
                    Nazad
                  </p>
                </li>
                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/tapaciraniNamestaj?kategorije=tapacirani+name%C5%A1taj&potkategorije=Setovi'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Setovi
                    </p>
                  </Link>
                </li>
                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/tapaciraniNamestaj?kategorije=tapacirani+name%C5%A1taj&potkategorije=Ugaone+garniture'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Ugaone garniture
                    </p>
                  </Link>
                </li>
                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/tapaciraniNamestaj?kategorije=tapacirani+name%C5%A1taj&potkategorije=trosed'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Trosedi
                    </p>
                  </Link>
                </li>
                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/tapaciraniNamestaj?kategorije=tapacirani+name%C5%A1taj&potkategorije=dvosed'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Dvosedi
                    </p>
                  </Link>
                </li>
                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/tapaciraniNamestaj?kategorije=tapacirani+name%C5%A1taj&potkategorije=fotelja'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Fotelje
                    </p>
                  </Link>
                </li>
              </div>
            )}

            {/* Drugi nivo: Trpezarije */}
            {window.innerWidth < 992 && (
              <div
                id='trpezarijeNav'
                className={
                  trpezarijeShow ? "#trpezarijeNav active" : "#trpezarijeNav"
                }
              >
                <li onClick={toggleTrpezarijeNav}>
                  <p style={{ color: "darkorange" }}>
                    <i
                      className='fa fa-chevron-left'
                      style={{ color: "white", fontSize: "15px" }}
                      aria-hidden='true'
                    ></i>{" "}
                    Nazad
                  </p>
                </li>
                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/trpezarije?kategorije=trpezarije&potkategorije=sto'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Stolovi
                    </p>
                  </Link>
                </li>
                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/trpezarije?kategorije=trpezarije&potkategorije=stolica'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Stolice
                    </p>
                  </Link>
                </li>
                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/trpezarije?kategorije=trpezarije&potkategorije=komoda'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Komode
                    </p>
                  </Link>
                </li>
                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/trpezarije?kategorije=trpezarije&potkategorije=ogledalo'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Ogledala
                    </p>
                  </Link>
                </li>
              </div>
            )}

            {/* Drugi nivo: Spavace sobe */}
            {window.innerWidth < 992 && (
              <div
                id='spavaceNav'
                className={spavaceShow ? "#spavaceNav active" : "#spavaceNav"}
              >
                <li onClick={toggleSpavaceNav}>
                  <p style={{ color: "darkorange" }}>
                    <i
                      className='fa fa-chevron-left'
                      style={{ color: "white", fontSize: "15px" }}
                      aria-hidden='true'
                    ></i>{" "}
                    Nazad
                  </p>
                </li>
                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/spavaceSobe?kategorije=spava%C4%87e+sobe&potkategorije=le%C5%BEaj'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Francuski ležajevi
                    </p>
                  </Link>
                </li>
                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/spavaceSobe?kategorije=spava%C4%87e+sobe&potkategorije=garderober'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Garderoberi
                    </p>
                  </Link>
                </li>
                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/spavaceSobe?kategorije=spava%C4%87e+sobe&potkategorije=no%C4%87ni+ormari%C4%87'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Noćni ormarići
                    </p>
                  </Link>
                </li>
                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/spavaceSobe?kategorije=spava%C4%87e+sobe&potkategorije=komoda'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Komode za šminkanje
                    </p>
                  </Link>
                </li>
                <li onClick={pageNumerOne}>
                  <Link
                    className='linkWraper'
                    to='https://www.elitehome.rs/spavaceSobe?kategorije=spava%C4%87e+sobe&potkategorije=ogledalo'
                  >
                    <p
                      style={{
                        fontSize: "16px",
                        fontWeight: "700",
                        textTransform: "uppercase",
                      }}
                    >
                      Ogledala
                    </p>
                  </Link>
                </li>
              </div>
            )}

            <li>
              <Link className='linkWraper' to='/onama'>
                <p>O nama</p>
              </Link>
            </li>

            <li>
              <Link className='linkWraper' to='/kontakt'>
                <p>Kontakt</p>
              </Link>
            </li>
          </ul>
        </div>

        <div id='mobile' onClick={handleClick}>
          <i id='bar' className={clicked ? "" : "fas fa-bars"}></i>
        </div>
      </nav>

      <div style={{ zIndex: 1040 }}>
        {isNavOpen && <OverlayNav onCloseNav={handleCloseNav} />}
      </div>

      <Carousel className='bg' activeIndex={index} onSelect={handleSelect}>
        <Carousel.Item>
          <Image
            className='d-block w-100'
            style={{ backgroundImage: "rgba(0, 0, 0, 0.5)" }}
            src={img2}
            alt='Aura'
            fluid
          />

          <Carousel.Caption>
            <h3
              className='animate__animated animate__bounceInRight'
              style={{ animationDelay: "1s" }}
            >
              Quatro
            </h3>
            <p
              className='animate__animated animate__bounceInLeft d-none d-md-block'
              style={{ animationDelay: "2s" }}
            >
              Moderna TV vitrina koja će uneti sofisticiranost u svaki
              enterijer.
            </p>

            <Link
              to='https://www.elitehome.rs/tvVitrine?kategorije=TV+vitrine'
              className='my-link'
            >
              <Button
                className='btn_carousel animate__animated animate__bounceInRight'
                style={{ animationDelay: "3s" }}
              >
                Pogledaj više
              </Button>
            </Link>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image className='d-block w-100' src={img1} alt='Valentino' fluid />
          <Carousel.Caption>
            <h3
              className='animate__animated animate__bounceInRight'
              style={{ animationDelay: "1s" }}
            >
              Dio
            </h3>
            <p
              className='animate__animated animate__bounceInLeft d-none d-md-block'
              style={{ animationDelay: "2s" }}
            >
              Zakoračite u Elite Home svet ekskluzivne ponude proizvoda premium
              kvaliteta.
            </p>

            <Link
              to='https://www.elitehome.rs/trpezarije?kategorije=trpezarije&potkategorije=trpezarije'
              className='my-link'
            >
              <Button
                className='btn_carousel animate__animated animate__bounceInRight'
                style={{ animationDelay: "3s" }}
              >
                Pogledaj više
              </Button>
            </Link>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item>
          <Image className='d-block w-100' src={img4} alt='Valentino' fluid />
          <Carousel.Caption>
            <h3
              className='animate__animated animate__bounceInRight'
              style={{ animationDelay: "1s" }}
            >
              Vetro
            </h3>
            <p
              className='animate__animated animate__bounceInLeft d-none d-md-block'
              style={{ animationDelay: "2s" }}
            >
              Učinite Vaš dom posebnim!
            </p>

            <Link
              to='https://www.elitehome.rs/tapaciraniNamestaj/38'
              className='my-link'
            >
              <Button
                className='btn_carousel animate__animated animate__bounceInRight'
                style={{ animationDelay: "3s" }}
              >
                Pogledaj više
              </Button>
            </Link>
          </Carousel.Caption>
        </Carousel.Item>

        <Carousel.Item className='carousel-item-bg'>
          <Image className='d-block w-100' src={img3} alt='Aura' fluid />
          <Carousel.Caption>
            <h3
              className='animate__animated animate__bounceInRight'
              style={{ animationDelay: "1s" }}
            >
              Aura
            </h3>
            <p
              className='animate__animated animate__bounceInLeft d-none d-md-block'
              style={{ animationDelay: "2s" }}
            >
              Luksuzne sofe iz kolekcije Aura sadrže ekskluzivne komade.
            </p>

            <Link
              to='https://www.elitehome.rs/tapaciraniNamestaj/39'
              className='my-link'
            >
              <Button
                className='btn_carousel animate__animated animate__bounceInRight'
                style={{ animationDelay: "3s" }}
              >
                Pogledaj više
              </Button>
            </Link>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  );
}
